import "./styles/globals.css";

function App() {
  return (
    <div className="App">
      <div className="main">
        <div className="content">
          <img
            src={require("./images/logo.jpg")}
            alt="audio visuel solution logo"
          />
          <p>
            Nous sommes une entreprise opérant dans toute la Suisse Romande,
            spécialisée dans l'installation de matériel audiovisuel pour des
            applications de communication, de présentation et d'enseignement.
            Nous proposons une gamme complète de services, comprenant la
            fourniture et l'installation de projecteurs, de moniteurs, d'écrans
            interactifs, de systèmes de vidéoconférence, de murs LED et bien
            d'autres encore.
          </p>
          <p>
            Nous collaborons étroitement avec nos clients afin de comprendre
            leurs besoins spécifiques et leur proposer des solutions
            personnalisées répondant à leurs exigences. N'hésitez pas à nous
            contacter pour améliorer la communication et la collaboration au
            sein de votre entreprise.
          </p>

          <div className="cols">
            <div className="col">
              <p>
                <strong>Alain Borloz</strong> (Conseil et vente)
                <br />
                <a href="mailto:aborloz@avsolution.ch">aborloz@avsolution.ch</a>
                <br />
                <a href="tel:0041793321247">+41 (0) 79 332 12 47</a>
              </p>
              <p>
                <strong>Christan Godel </strong> (Service technique)
                <br />
                <a href="mailto:cgodel@avsolution.ch">cgodel@avsolution.ch</a>
                <br />
                <a href="tel:0041793321248">+41 (0) 79 332 12 48</a>
              </p>
            </div>
            <div className="col">
              <p>
                <strong>Audiovisuel Solution Sàrl</strong>
                <br />
                Château-Dessus 9b
                <br />
                1376 Goumoëns-la-Ville
                <br />
                +41(0) 21 881 66 77
                <br />
                <a href="mailto:info@avsolution.ch">info@avsolution.ch</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
